<template>
  <router-link
    :to="link"
    class="card type-creation"
    :class="[ `size-${size}`, `type-${cardData.creationType}` ]"
    :style="`--mobile-order: ${cardData.mobileOrder}`"
  >
    <div class="image">
      <div class="gradient-overlay"></div>
    </div>
    <div class="content">
      <div class="button-like">
        {{ texts[cardData.creationType].button }}
      </div>
      <div class="description">
        <span v-for="(text, index) in texts[cardData.creationType].description" :key="index">
          {{ text }}
        </span>
      </div>
      <!--<span class="title">
        {{ texts[cardData.creationType].title }}
      </span>-->
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CreationCard',
  props: ['cardData'],

  data() {
    return {
      texts: {
        user: {
          button: 'Создать канал',
          description: [
            'Создайте канал в Helperia для себя, компании, проекта, идеи...',
            'Общайтесь, дискутируйте, пишите статьи, рассказывайте о событиях.',
            'Делитесь мыслями и эмоциями, фото и видео.',
            'Привлекайте партнеров.',
          ],
          title: 'Создайте канал!',
        },
        article: {
          button: 'Написать статью',
          description: [
            'Поделитесь своими знаниями, опытом и исследованиями.',
            'Расскажите интересные истории.',
            'Ваша новая статья будет на главной странице сайта.',
            'Мы продвигаем лучшие статьи.',
          ],
          title: 'Напишите статью!',
        },
        question: {
          button: 'Задать вопрос',
          description: [
            'Задайте интересующий вопрос и получите ответ от наших экспертов и посететителей.',
            'Это можно сделать анонимно.',
          ],
          title: 'Задайте вопрос!',
        },
        event: {
          button: 'Сообщить о событии',
          description: [
            'Вы создаёте интересное событие, расскажите о нем.',
            'Разместите анонс. Привлекайте гостей и партнеров.',
            'Посетили выставку, лекцию, спектакль... оставьте отзыв.',
          ],
          title: 'Сообщите о публичном событии!',
        },
        discussion: {
          button: 'Начать дискуссию',
          description: [
            'Давайте обсудим интересующие нас проблемы.',
            'Поговорим на горячие темы.',
            'Обменяемся мнениями и получим советы от разных людей.',
          ],
          title: 'Начните дискуссию!',
        },
      }
    }
  },

  computed: {
    link() {
      if(this.cardData.creationType === 'user') {
        return { name: 'register' }
      }

      return { name: `new-${this.cardData.creationType}` }
    },

    size() {
      if(this.cardData.size) {
        return this.cardData.size
      }

      return 'high'
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../../../card-shared.less';

  .card {
    border: @xxs-margin solid var(--type-color);
    box-sizing: border-box;
    --image: url('../../../assets/creation-cards/generic.jpg');
    --color: 99%,99%,99%;
    --gradient-direction: to top;
    flex-direction: column-reverse;
    background-color: @color-block-background;

    @media @tablet-m {
      order: var(--mobile-order);
    }

    &.size-wide {
      flex-direction: row;
      --gradient-direction: to right;
    }

    &.size-small {
      justify-content: center;
    }

    @media @tablet-m {
      justify-content: center;
    }
  }

  .image {
    flex-basis: 45%;

    .size-small & {
      display: none;
    }

    @media @tablet-m {
      display: none;
    }
  }

  .content {
    padding: @s-margin;
    display: flex;
    flex-direction: column;
    max-width: @card-width;

    .size-wide &,
    .size-small & {
      //flex-direction: column-reverse;
      justify-content: center;
    }

    @media @tablet-m {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  .button-like {
    background-color: var(--type-color);
    font-weight: bold;
    padding: 0.4em 0.6em;
    color: @color-block-background;
    align-self: flex-start;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin: 0.5em 0;

    span {
      margin-top: 0.25em;

      &:first-child {
        margin-top: unset;
      }

      .size-wide &:nth-child(n+3),
      .size-small &:nth-child(n+3) {
        display: none;
      }

      @media @tablet-m {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }

  .title {
    font-family: @font-set-header;
    font-size: 14pt;

    .size-high & {
      display: none;

      @media @tablet-m {
        display: unset;
      }
    }
  }
</style>
