<template>
  <div class="card-browser">
    <div class="card-set">
      <template v-for="card in cards">
        <ContentCard :key="`${card.type}-${card.id}`" :card-data="card" v-if="card.type !== 'creation'" />
        <CreationCard :key="`${card.type}-${card.id}`" :card-data="card" v-if="card.type === 'creation'" />
      </template>
    </div>
    <div class="paginator" v-if="pageCount > 0">
      <router-link
        :to="{ query: { page: (page === 1) ? 1 : (page - 1) } }"
        :class="{ disabled: page < 2 }"
        class="important"
      >
        <img src="../../assets/icons/arrow-left.svg" alt="На предыдущую страницу">
      </router-link>
      <router-link
        v-for="(page, key) in pageSet"
        :key="key"
        :to="(page === -1) ? {} : getQueryForPage(page)"
        :class="{
          active: $props.page === page,
          ellipsis: page === -1,
          important: page === 1 || page === pageCount,
        }"
      >
        {{ (page === -1) ? '…' : page }}
      </router-link>
      <router-link :to="{ query: { page: page + 1 } }" :class="{ disabled: page >= pageCount }" class="important">
        <img src="../../assets/icons/arrow-right.svg" alt="На следующую страницу">
      </router-link>
    </div>
  </div>
</template>

<script>
import ContentCard from '@/components/Shared/Cards/ContentCard'
import CreationCard from '@/components/Shared/Cards/CreationCard'

export default {
  name: 'CardBrowser',
  components: { CreationCard, ContentCard },
  props: ['cards', 'page', 'pageCount'],

  computed: {
    pageSet() {
      const page = parseInt(this.page)

      const pages = [1]
      if(this.pageCount === 1) {
        return pages
      }

      if(page > 4) {
        pages.push(-1)
      }

      const midPages = [page - 2, page - 1, page, page + 1, page + 2]
      const filteredMidPages = midPages.filter(page => page > 1 && page < this.pageCount)
      pages.push(...filteredMidPages)

      if(page < this.pageCount - 3) {
        pages.push(-1)
      }

      pages.push(this.pageCount)
      return pages
    },
  },

  methods: {
    getQueryForPage(page) {
      if(!page || page < 1) {
        page = 1
      }

      const query = { ...this.$route.query }
      query.page = page

      return { query }
    }
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .card-browser {
    align-self: center;
  }

  .card-set {
    display: grid;
    grid-template-columns: repeat(3, @card-width);
    grid-auto-rows: @card-small-height;
    grid-gap: @s-margin;
    justify-content: center;

    @media @tablet-m {
      grid-template-columns: repeat(2, @card-width);
    }

    @media @mobile-l {
      grid-template-columns: @card-width;
    }
  }

  .paginator {
    display: flex;
    margin-top: 2em;

    @media @tablet-m {
      justify-content: center;
    }

    a {
      @transition-parameters: 0.1s linear 0s;
      @size: 40px;
      width: @size;
      height: @size;
      box-sizing: border-box;
      border: 1px solid @color-accent;
      color: @color-text;
      font-size: 11pt;
      transition: background-color @transition-parameters, color @transition-parameters;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: @xxs-margin;

      &:first-child {
        margin-left: unset;
      }

      &:hover, &.active {
        background-color: @color-accent;
        color: @color-block-background;

        img {
          filter: invert(100%);
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.ellipsis {
        pointer-events: none;
        opacity: 0.5;
        width: unset;
        border: unset;
      }

      img {
        filter: invert(0%);
        transition: filter @transition-parameters;
      }

      @media @mobile-l {
        display: none;

        &.important,
        &.active {
          display: flex;
        }
      }
    }
  }
</style>
