<template>
  <main>
    <div class="user-info" v-if="userInfo">
      <div class="visual">
        <!--suppress HtmlUnknownTarget -->
        <img class="avatar" :src="avatarImage" alt="Аватар">
        <span class="profile-type type-user">Эксперт</span>
      </div>
      <div class="description">
        <h1>Канал {{ userInfo.username }}</h1>
        <p v-for="descriptionField in filteredDescriptionFields" :key="descriptionField">
          <strong>{{ descriptionFieldDisplayNames[descriptionField] }}</strong>: {{ userInfo[descriptionField] }}
        </p>
        <div class="stats" v-if="userInfo['views'] || userInfo.rating || userInfo['commentCount']">
          <span class="views" v-if="userInfo['views']">
              {{ userInfo['views'] }}
              <img src="../assets/icons/views.svg" alt="Просмотры">
            </span>
          <span class="rating" v-if="userInfo.rating">
              {{ userInfo.rating }}
              <img src="../assets/icons/rating-up.svg" alt="Рейтинг">
            </span>
          <span class="comments" v-if="userInfo['commentCount']">
              {{ userInfo['commentCount'] }}
              <img src="../assets/icons/comments.svg" alt="Комментарии">
            </span>
        </div>
        <div class="elevated-actions" v-if="userInfo.permissions.canSeeServicePages">
          <router-link :to="{ name: 'tag-manager' }">Менеджер тегов</router-link>
        </div>
      </div>
    </div>
    <div class="utils" v-if="$root.auth && $root.auth.username === slug">
      <!--<router-link :to="{ name: 'edit-profile' }" class="button edit-profile">Редактировать профиль</router-link>-->
      <router-link :to="{ name: 'change-username' }" class="button change-username">Сменить имя</router-link>
      <router-link :to="{ name: 'change-password' }" class="button change-password">Сменить пароль</router-link>
    </div>
    <div class="creator-utils" v-if="$root.auth && $root.auth.username === slug">
      <router-link :to="{ name: 'new-article' }" class="button new-content type-article">+ Написать статью</router-link>
      <router-link :to="{ name: 'new-event' }" class="button new-content type-event">+ Сообщить о событии</router-link>
      <!--<router-link :to="{ name: 'new-question' }" class="button new-content type-question">+ Задать вопрос</router-link>-->
      <router-link :to="{ name: 'new-discussion' }" class="button new-content type-discussion">+ Начать дискуссию</router-link>
    </div>
    <CardBrowser v-if="cards" :cards="cards" :page="page" :page-count="pageCount" />
    <LoadingBlock v-if="!userInfo || !cards"/>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
import CardBrowser from '@/components/Shared/CardBrowser'

export default {
  name: 'UserProfile',
  components: { CardBrowser, LoadingBlock },
  props: [
    'slug',
  ],

  data() {
    return {
      userInfo: null,
      cards: null,
      cardAmount: 0,

      descriptionFieldDisplayNames: {
        realName: 'Имя',
        birthday: 'Дата рождения',
        occupation: 'Род деятельности',
        hobbies: 'Интересы',
        location: 'Место жительства',
        education: 'Образование',
        about: 'О канале',
      },
      cardsPerPage: 18,
    }
  },

  mounted() {
    this.getUserInfo()
  },

  watch: {
    $route: 'updateRoute',
  },

  methods: {
    updateRoute(newRoute, oldRoute) {
      if(newRoute.name !== 'user-profile') {
        return
      }

      if(oldRoute.name !== 'user-profile') {
        this.getUserInfo()
        return
      }

      if(newRoute.params.slug !== oldRoute.params.slug) {
        this.getUserInfo()
        return
      }

      this.getCards()
    },

    getUserInfo() {
      this.userInfo = null
      this.cards = null
      this.cardAmount = 0

      this.$root.makeAPIRequest('user', 'get', {
        slug: this.slug,
      })
        .then(response => {
          if(response.data.error) {
            switch(response.data.handler) {
              case 'not-found':
                this.$router.replace({ name: 'not-found' })
                return
            }

            console.error(response.data.message)
            alert('Невозможно загрузить данные. Пожалуйста попробуйте обновить страницу.')
            return
          }

          this.userInfo = response.data.result
          this.$root.setTitle(`Канал ${this.userInfo.username}`)
          this.$root.setCanonical(`/u/${encodeURIComponent(this.userInfo.username)}`)
          this.getCards()
        })
    },

    getCards() {
      this.cards = null
      this.cardAmount = 0

      this.$root.makeAPIRequest('user', 'get_content', {
        slug: this.slug,
        cardsPerPage: this.cardsPerPage,
        offset: this.offset,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            this.cards = []
            return
          }

          this.cardAmount = response.data.result.amount
          this.cards = response.data.result.cards
        })
    },
  },

  computed: {
    filteredDescriptionFields() {
      const descriptionFields = Object.keys(this.descriptionFieldDisplayNames)
      const filteredFields = descriptionFields.filter(key => this.userInfo[key])
      return filteredFields
    },

    avatarImage() {
      if(!this.userInfo.image) {
        return '/images/generics/avatar-anonymous.svg'
      }

      return `${this.$root.globalParams.developerBaseURI}${this.userInfo.image}`
    },

    page() {
      if(!this.$route.query.page || Array.isArray(this.$route.query.page)) {
        return 1
      }

      // noinspection JSCheckFunctionSignatures
      const pageNum = parseInt(this.$route.query.page)
      if(isNaN(pageNum)) {
        return 1
      }

      if(pageNum < 1) {
        return 1
      }

      return pageNum
    },

    offset() {
      return (this.page - 1) * this.cardsPerPage
    },

    pageCount() {
      return Math.ceil(this.cardAmount / this.cardsPerPage)
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
  }

  .user-info {
    display: flex;

    @media @mobile-l {
      flex-direction: column;
    }
  }

  .visual {
    margin-right: @m-margin;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media @mobile-l {
      flex-direction: row;
      margin-bottom: @s-margin;
    }
  }

  .avatar {
    --size: 170px;

    width: var(--size);
    height: var(--size);

    @media @tablet-s {
      --size: 125px;
    }

    @media @mobile-l {
      --size: 80px;
    }
  }

  .profile-type {
    margin-top: @s-margin;
    background-color: var(--type-color);
    color: @color-block-background;
    font-weight: 600;
    padding: 0.4em 1em;

    @media @mobile-l {
      margin-top: unset;
      margin-left: @s-margin;
    }
  }

  .description {
    overflow: hidden;
    overflow-wrap: break-word;

    p {
      margin: 0.5em 0;
    }
  }

  .stats {
    opacity: 0.7;
    margin-top: 1em;

    span+span {
      margin-left: @s-margin;
    }
  }

  .elevated-actions {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-decoration: underline;
    align-items: flex-start;

    a {
      color: @color-text;
    }
  }

  .utils {
    margin-top: @s-margin;
    display: flex;

    @media @mobile-l {
      flex-direction: column;
      margin-top: unset;
      align-items: flex-start;
    }

    .button {
      .big-button();
      background-color: @color-accent;
      margin-left: @s-margin;

      @media @mobile-l {
        margin-top: @s-margin;
        margin-left: unset;
      }

      &:first-child {
        margin-left: unset;
      }
    }
  }

  .creator-utils {
    margin-top: 1em;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      background-color: var(--type-color);
      padding: 0.4em 0.6em;
      color: @color-block-background;
      margin-top: @xxs-margin;

      &:first-child {
        margin-top: unset;
      }
    }
  }

  .card-browser {
    margin-top: @s-margin;
  }
</style>
